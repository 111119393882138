<script setup lang="ts">
import { LineItem } from '@shopware-pwa/types';

defineProps<{ buttonClasses?: string }>();

const { appliedPromotionCodes, addPromotionCode, removeItem } = useCart();
const { codeErrorsNotification } = useCartNotification();
const addPromotionCodeHandler = async (code: string) => {
  await addPromotionCode(code);
  codeErrorsNotification();
  promoCode.value = '';
};

const removeItemHandler = (appliedPromotionCode: LineItem) => {
  removeItem(appliedPromotionCode);
  codeErrorsNotification();
};

const showPromotionCodes = computed(
  () => appliedPromotionCodes.value.length > 0
);

const promoCode = ref('');
const showPromoCodeInput = ref(false);
</script>

<template>
  <div class="flex flex-col border-dark border-y">
    <button
      class="flex items-center justify-between bg-white text-primary py-4 uppercase text-sm font-bold"
      @click="showPromoCodeInput = !showPromoCodeInput"
    >
      {{ $t('cart.promocode.title') }}
      <i
        v-if="showPromoCodeInput"
        aria-hidden
        class="block icon i-custom-svg:minus text-primary text-xl"
      ></i>
      <i
        v-else
        aria-hidden
        class="block icon i-custom-svg:plus text-primary text-xl"
      ></i>
    </button>
    <div class="grid grid-cols-5 gap-2 relative pb-6" v-if="showPromoCodeInput">
      <SharedSpecialInput
        additional-classes="h-full"
        class="col-span-3"
        v-model="promoCode"
        :label="$t('cart.promocode.label')"
        name="promoCode"
      />
      <button
        class="btn btn-solid col-span-2 text-center"
        @click="addPromotionCodeHandler(promoCode)"
      >
        {{ $t('cart.promocode.apply') }}
      </button>
    </div>
  </div>
  <div v-if="showPromotionCodes">
    <div>{{ $t('checkout.promoCode.label') }}:</div>
    <ul role="list" class="text-sm pl-0">
      <li
        v-for="appliedPromotionCode in appliedPromotionCodes"
        :key="appliedPromotionCode.id"
        class="flex justify-between text-gray-600 border-b py-4"
      >
        <span>{{ appliedPromotionCode.label }}</span>
        <button
          class="text-brand-dark appearance-none bg-transparent border-none"
          type="button"
          @click="() => removeItemHandler(appliedPromotionCode)"
        >
          {{ $t('checkout.promoCode.remove') }}
        </button>
      </li>
    </ul>
  </div>
</template>
